import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { API_BASE_URL } from '../api/api_base_url';
import './../../pages/stats/LineChart.css'; 

const LineChart: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [timeFrame, setTimeFrame] = useState<'7_days' | '30_days' | '365_days' | 'all_days'>('7_days');

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios.get(`${API_BASE_URL}/api/stats/${timeFrame}`);
            console.log(result);
            setData(result.data);
        };

        fetchData();
    }, [timeFrame]);

    const chartData = {
        labels: data.map(item => item.date),
        datasets: [
            {
                label: 'Searches per day',
                data: data.map(item => item.searches),
                fill: false,
                backgroundColor: '#003C50',
                borderColor: '#003C50',
                borderWidth: 4,
            },
            {
                label: 'OpenAI calls per day',
                data: data.map(item => item.openai_calls),
                fill: false,
                backgroundColor: '#F07D00',
                borderColor: '#F07D00',
                borderWidth: 4,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                labels: {
                    font: {
                        weight: 'bold' as const, // Make legend labels bold
                    },
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false, // Optional: Disable the x-axis grid lines
                },
                border: {
                    display: false, // Disable the x-axis line
                },
            },
            y: {
                border: {
                    display: false, // Disable the y-axis line
                },
            },
        },
    };

    return (
        <div className="chart-container">
            <select 
                value={timeFrame} 
                onChange={(e) => setTimeFrame(e.target.value as '7_days' | '30_days' | '365_days' | 'all_days')}
                className="custom-dropdown"
            >
                <option value="7_days">7 days</option>
                <option value="30_days">30 days</option>
                <option value="365_days">365 days</option>
                <option value="all_days">Total days</option>
            </select>
            <Line data={chartData} options={options} height='300px' width='1000px'/>
        </div>
    );
};

export default LineChart;
