import {
  IStackStyles,
  ISpinButtonStyles,
  IToggleStyles,
  ISpinnerStyles,
  IStyle,
  mergeStyles,
} from '@fluentui/react';

export const textStyle: React.CSSProperties = {
  fontFamily: 'Roboto, sans-serif',
  color: '#003c50',
};

export const statsHeaderDiv = {
  display: 'block',
  marginRight: '5px',
  width: '220px',
};

export const searchHeaderDiv = {
  display: 'block',
  marginRight: '5px',
  width: '240px',
  color: '#003c50',
};

export const searchLoadSettingsDiv = {
  display: 'block',
  marginTop: '50px',
  marginBottom: '50px',
  marginRight: '20px',
  width: '100%',
};

export const searchSaveSettingsDiv = {
  display: 'block',
  marginTop: '50px',
  marginBottom: '30px',
  marginRight: '20px',
  width: '100%',
  overflowY: 'auto',
};

export const searchSettingsDiv = {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '3px',
  marginRight: '30px',
  marginLeft: '30px',
  fontSize: '0.9rem',
  fontFamily: 'Roboto',
  flexBasis: '200px', // Grundbreite jedes Elements
  flexGrow: 1, // Ermöglicht Flexibilität beim Wachsen
  flexShrink: 1, // Ermöglicht Flexibilität beim Schrumpfen
  minWidth: '150px', // Mindestbreite, um eine zu kleine Darstellung zu vermeiden
};

export const searchSettingsDivSmall = {
  display: 'block',
  marginTop: '3px',
  marginRight: '30px',
  width: '100%',
  minHeight: '60px',
  marginLeft: '30px',
  fontSize: '0.9rem',
  fontFamily: 'Roboto',
};

export const searchSettingsPromptDiv = {
  display: 'block',
  marginTop: '10px',
  marginRight: '30px',
  width: '33%',
  marginLeft: '30px',
};
export const stackSearchSettings: Partial<IStackStyles> = {
  root: {
    marginBottom: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
    paddingLeft: '60px',
    paddingRight: '60px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '20px', // Fügt Abstände zwischen den Elementen hinzu
  },
};

export const stackSearchSettingsSecondGroup: Partial<IStackStyles> = {
  root: mergeStyles(stackSearchSettings.root, {
    paddingTop: '20px', // Fügt einen Abstand von 20px nach oben hinzu
  }),
};

export const statisticsDivTest = {
  display: 'block',
  marginTop: '5px',
  marginRight: '20px',
  width: '100%',
};

export const statisticsDivGraph = {
  marginRight: '1px',
  marginLeft: '1px',
};

export const statisticsDiv15 = {
  display: 'block',
  marginTop: '5px',
  marginRight: '1%',
  marginLeft: '1%',
  width: '15%',
};

export const statisticsDiv20 = {
  display: 'block',
  marginTop: '5px',
  marginRight: '1%',
  marginLeft: '1%',
  width: '20.66%',
};

export const statisticsDivSmall = {
  display: 'block',
  marginBottom: '2%',
  marginTop: '2%',
  marginRight: '1%',
  width: '90%',
  height: '23.5%',
};

export const stackStatistics: Partial<IStackStyles> = {
  root: {
    marginBottom: '1%',
    justifyContent: 'center',
  },
};

export const labelStyleEmpty = {
  root: {
    fontSize: '0px',
    marginTop: '0px',
    marginBottom: '0px',
  },
};

export const labelStyleBox = {
  root: {
    fontSize: '14px',
    marginTop: '0%',
    marginBottom: '0.1px',
    marginLeft: '3%',
    fontFamily: 'Roboto',
    color: '#646363',
  },
};

export const labelStyleGraph = {
  root: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginTop: '1%',
    marginBottom: '1px',
    marginRight: '20px',
    marginLeft: '1%',
    fontFamily: 'Roboto',
    color: '#003c50',
  },
};

export const labelStyle = {
  root: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    marginTop: '2%',
    marginBottom: '1px',
    marginRight: '20px',
    marginLeft: '2%',
    fontFamily: 'Roboto',
    color: '#003c50',
  },
};

export const labelSettingDetailsStyle = {
  root: {
    fontWeight: 'bold',
    fontSize: '1.25rem',
    fontFamily: 'Roboto',
    color: '#003c50',
  },
};

export const buttonStyleExpander = {
  root: {
    width: 110,
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#003c50',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
  },
  rootHovered: {
    backgroundColor: '#003c50d4',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: '#003c50',
    outline: 'none',
    borderColor: 'transparent',
  },
};

export const textFieldNewSearchSetting = {
  root: {
    width: '300px',
    fontFamily: 'Roboto',
    borderRadius: '0.5rem',
    marginRight: '5px',
  },
  fieldGroup: {
    color: '#003c50',
    borderRadius: '0.5rem',
    border: '1px solid #ccc',
    padding: '2px',
    selectors: {
      ':hover': {
        borderColor: '#003c50',
      },
      ':focus': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      ':focus-within': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      '::after': {
        borderRadius: '0.5rem !important',
        border: '2px solid #003c50 !important',
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
    },
  },
  field: {
    fontFamily: 'Roboto',
    color: '#003c50',
    resize: 'vertical',
  },
  subComponentStyles: {
    label: {
      root: {
        selectors: {
          ':focus-within': {
            color: '#639aaf',
            borderRadius: '0.5rem',
          },
        },
      },
    },
  },
};

export const toggleStyles: Partial<IToggleStyles> = {
  root: {
    fontFamily: 'Roboto',
    selectors: {
      '&.is-checked .ms-Toggle-background': {
        color: '#ccc',
        borderColor: '#003c50 !important',
        backgroundColor: '#003c50',
      },
    },
  },
  pill: {
    borderColor: '#ccc',
    ':hover': {
      borderColor: '#ccc !important',
    },
    ':hover .ms-Toggle-thumb': {
      borderColor: '#003c50 !important',
    },
  },
};

export const buttonStyleLoadDelSavedSetting = {
  root: {
    width: '10%',
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#F07D00',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginLeft: '5px',
  },
  rootHovered: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootFocused: {
    backgroundColor: '#F07D00',
    outline: 'none',
    borderColor: 'black',
  },
  rootDisabled: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
};

export const buttonStyleResetSetting = {
  root: {
    width: '10%',
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#F07D00',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginLeft: 'auto',
  },
  rootHovered: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootFocused: {
    backgroundColor: '#F07D00',
    outline: 'none',
    borderColor: 'black',
  },
};

export const buttonStyleSaveSetting = {
  root: {
    width: '10%',
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#F07D00',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginLeft: '10px',
  },
  rootHovered: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootFocused: {
    backgroundColor: '#F07D00',
    outline: 'none',
    borderColor: 'black',
  },
  rootDisabled: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
};

export const buttonStyleSearch = {
  root: {
    width: '100%',
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#F07D00',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginLeft: '20px',
  },
  rootHovered: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootFocused: {
    backgroundColor: '#F07D00',
    outline: 'none',
  },
};

export const textFieldStyleSearch = {
  fieldGroup: {
    color: '#003c50',
    borderRadius: '0.5rem',
    border: '1px solid #ccc',
    padding: '2px',
    selectors: {
      ':hover': {
        borderColor: '#003c50',
      },
      ':focus': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      ':focus-within': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      '::after': {
        borderRadius: '0.5rem !important',
        border: '2px solid #003c50 !important',
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
    },
  },
  root: {
    width: '100%',
  },
  field: {
    fontFamily: 'Roboto',
    color: '#003c50',
    resize: 'vertical',
  },
  subComponentStyles: {
    label: {
      root: {
        selectors: {
          ':focus-within': {
            color: '#639aaf',
            borderRadius: '0.5rem',
          },
        },
      },
    },
  },
};

export const expanderDivStyle = {
  root: {
    padding: '20px',
    marginBottom: '30px',
    //backgroundColor: '#dce5e754',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: '0 2px 2px rgba(0,0,0,.3)',
    borderRadius: '.5rem',
    overflowY: 'auto',
    margin: 16,
    display: 'block',
    maxWidth: '95%',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

export const expanderSettingDetailsStyle = {
  root: {
    backgroundColor: '#dce5e730',
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '.5rem',
    overflowY: 'auto',
    marginLeft: '50px',
    marginRight: '50px',
    marginBottom: '20px',
    marginTop: '20px',
    paddingBottom: '10px',
  },
};

export const stackExpanderField = {
  root: {
    margingBottom: '5px',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '4px',
  },
};

export const buttonSettingsDetailsStyle = {
  root: {
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#003e52',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginRight: 10,
    color: 'white',
  },
  rootHovered: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootPressed: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootFocused: {
    backgroundColor: '#003e52',
    outline: 'none',
    color: 'white',
  },
};

export const textFieldItem = {
  root: {
    //marginTop: 10,
    width: '90%',
  },
};

export const buttonItem = {
  root: {
    width: '10%',
  },
};

export const searchTextFieldStackStyle = {
  root: {
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '10px',
    borderRadius: '.25rem',
  },
};

export const stackSearchField = {
  root: {
    width: '100%',
    paddingRight: '20px',
    overflowX: 'hidden',
    minWidth: '100px',
    marginTop: '10px',
    display: 'flex',
    flexWrap: 'nowrap',
  },
};

export const searchResultDivStyle = {
  height: 'auto',
  width: '100%',
};

export const spinButtonStyle: Partial<ISpinButtonStyles> = {
  root: {
    maxWidth: 150,
    marginBottom: 0,
    borderRadius: '0.5rem !important',
  },
  spinButtonWrapper: {
    selectors: {
      '::after': {
        borderRadius: '0.5rem !important',
        border: '1px solid #ccc !important',
        content: '""',
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
      '.ms-Dropdown-caretDownWrapper': {
        borderRadius: '0.5rem',
        backgroundColor: '#ffffff',
        color: '#003c50',
        selectors: {
          ':hover': {
            color: '#000000',
          },
          ':focus': {
            outline: 'none',
            color: '#000000',
          },
        },
      },
    },
  },
};

export const spinButtonProjectMaxDistanceStyle: Partial<ISpinButtonStyles> = {
  root: {
    maxWidth: 150,
    marginTop: 5,
    marginBottom: 15,
    borderRadius: '0.5rem !important',
  },
  spinButtonWrapper: {
    selectors: {
      '::after': {
        borderRadius: '0.5rem !important',
        border: '1px solid #ccc !important',
        content: '""',
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
      '.ms-Dropdown-caretDownWrapper': {
        borderRadius: '0.5rem',
        backgroundColor: '#ffffff',
        color: '#003c50',
        selectors: {
          ':hover': {
            color: '#000000',
          },
          ':focus': {
            outline: 'none',
            color: '#000000',
          },
        },
      },
    },
  },
};

export const messageBarTagsStyle = {
  root: {
    borderRadius: '0.5rem',
    fontFamily: 'Roboto',
    overflowY: 'auto',
    color: '#003c50',
  },
};

export const spinnerStyle: Partial<ISpinnerStyles> = {
  circle: {
    borderTopColor: '#F07D00',
    borderRightColor: '#f07d004d',
    borderLeftColor: '#f07d004d',
    borderBottomColor: '#f07d004d',
  },
  label: {
    color: '#F07D00',
  },
};

export const geospatialTextfieldStyle = {
  root: {
    maxWidth: 150,
    marginTop: 5,
    marginBottom: 15,
  },
  fieldGroup: {
    borderRadius: '0.5rem',
    border: '1px solid #ccc',
    padding: '2px',
    selectors: {
      ':hover': {
        borderColor: '#003c50',
      },
      ':focus': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      ':focus-within': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      '::after': {
        borderRadius: '0.5rem !important',
        border: '2px solid #003c50 !important',
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
    },
  },
  field: {
    fontFamily: 'Roboto',
    resize: 'vertical',
  },
};
