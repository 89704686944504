import React, { useState, useEffect } from 'react';
import { TextField, DefaultButton } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { buttonEditPromptStyle, textFieldPromptSearchSetting, stackDynamicItems, stackDynamicSinglePromptItem } from './react_styles_ui';

interface Props {
    textValue: string;
    onChange: (newValue: string) => void; // Adjusted onChange prop type
};


const PromptTextfield: React.FC<Props> = ({ textValue, onChange }) => {
    
    // Initialize text state
    const [text, setText] = useState(textValue);
    const [expandedEdit, setExpandedEdit] = useState(false);

    // Handle add items
    const toggleEditExpand = () => { setExpandedEdit(!expandedEdit) }; 
    const handleTextChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = (event.target as HTMLInputElement | HTMLTextAreaElement).value;
        setText(newValue);
        if (!newValue.trim()) {
            alert('Prompt text cannot be empty! If empty, previous state is used.');
        } else {
            if (onChange) onChange(newValue);
        }
    };

    // Update text state
    useEffect(() => {
        setText(textValue);
    }, [textValue]);

    // Construct PromptTextfield component
    return (
        <div>
            <DefaultButton 
                onClick={toggleEditExpand}
                styles={buttonEditPromptStyle}
                iconProps={{ iconName: expandedEdit ? 'Hide' : 'Edit' }}
            >
            </DefaultButton> 
            {expandedEdit && (
                <Stack 
                    horizontal 
                    tokens={stackDynamicItems} 
                    styles={stackDynamicSinglePromptItem}
                >
                    <Stack.Item align="center">
                        <TextField 
                            multiline rows={15} 
                            value={text}
                            onChange={handleTextChange}
                            styles={textFieldPromptSearchSetting} 
                        />
                    </Stack.Item>
                </Stack>
            )}
        </div>
    );
};

export default PromptTextfield;