import React, { useEffect, useState } from 'react';
import { Doughnut} from 'react-chartjs-2'; // Assuming you are using chart.js
import { API_BASE_URL } from '../api/api_base_url';
import axios from 'axios';
import 'chart.js/auto';

interface DoughnutChartData {
    labels: string[];
    data: number[];
    colors: string[];
}

interface DoughnutChartComponentProps {
    apiPath: string; // Input string parameter
}

const DoughnutChartComponent: React.FC<DoughnutChartComponentProps> = ({ apiPath }) => {
    const [data, setData] = useState<DoughnutChartData | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${API_BASE_URL}/api/stats/${apiPath}`);
            const result = await response.data;
            setData(result);
        };

        fetchData();
    }, [apiPath]);

    if (!data) {
        return <p>Loading...</p>;
    }

    const chartData = {
        labels: data.labels,
        datasets: [
            {
                data: data.data,
                backgroundColor: data.colors,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                position: 'bottom' as const,
                labels: {
                    font: {
                        weight: 'bold' as const,
                        family: 'Roboto',
                        size: 16,  // Make the legend text bold
                    },
                },
            },
        },
        cutout: "70%"
    };


    return <Doughnut data={chartData} options={options}/>;
};

export default DoughnutChartComponent;
