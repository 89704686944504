import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { PrimaryButton, Stack, Label } from '@fluentui/react';
import { useAuth } from '../../components/auth/auth_context';
import dfine from '../../assets/d-fine_logo-blue_RGB.svg'
import { 
  loginContainer, 
  loginBox,
  loginSvgStyle,
  loginSvgStyleLogo,
  labelStyleLogin,
  labelStyleApp,
  labelStyleAccessDenied,
  stackLogin,
  loginTextStyle,
  loginDeniedTextStyle,
  buttonStyleLogin,
  buttonStyleLoginCloseModal,
  loginDeniedModalStyle
} from './react_styles_login';

interface LoginProps {
  onLogin: () => void;
}


const Login: React.FC<LoginProps> = ({ onLogin }) => {

  // Initialize states and constants
  const { user, isAllowedRole } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // Update modal based on user state
  useEffect(() => {
    if (user) {
      if (!isAllowedRole(user.role)) {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    }
  }, [user, isAllowedRole]);

  // Function to handle closing modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Construct Login component
  return (
    <>
      <Stack horizontal>
        <img src={dfine} alt="d-fine logo" aria-label="Link to d-fine" className={loginSvgStyleLogo} />
        <Label styles={labelStyleApp}>Skill Search App</Label>
      </Stack>
      <div className={loginContainer}>
        <div className={loginBox}>
          <Stack
            horizontal
            styles={stackLogin} 
          >
            <img src={dfine} alt="d-fine logo" aria-label="Link to d-fine" className={loginSvgStyle} />
            <Label styles={labelStyleLogin}>Skill Search Login</Label>
          </Stack>
          <p className={loginTextStyle}>Login with your Microsoft 365 account</p>
          <PrimaryButton onClick={onLogin} styles={buttonStyleLogin}>Microsoft 365</PrimaryButton>
        </div>
        <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Role Not Allowed" style={loginDeniedModalStyle} ariaHideApp={false}>
          <Label styles={labelStyleAccessDenied}>Access denied!</Label>
          <p className={loginDeniedTextStyle}>Your role does not permit access to this application.</p>
          <PrimaryButton onClick={closeModal} styles={buttonStyleLoginCloseModal}>Close</PrimaryButton>
        </Modal>
      </div>
    </>
  );
};

export default Login;