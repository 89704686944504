import React, { useEffect, useState } from 'react';
import { Text} from '@fluentui/react';
import axios from 'axios';
import { API_BASE_URL } from '../api/api_base_url';

interface ApiAdress {
    apiPath: string; // Input string parameter
}

const StatsValue: React.FC<ApiAdress> = ({ apiPath }) => {
    const [value, setValue] = useState<string | null>(null);

    // Fetch data from backend
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/stats/${apiPath}`);
                const result = response.data;
                setValue(result.value);
            } catch (error) {
                console.error('Error fetching bar chart data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Text 
            variant="medium" 
            styles={{ root: { fontSize: '18px', lineHeight: '1.5', marginTop: '0px',  marginBottom: '0px', marginLeft: '3%', fontWeight:'bold', fontFamily: 'Roboto', color: '#003c50',} }}>
            {value ?? 'Loading...'} 
        </Text>
    );
};

export default StatsValue;
