import { Tag } from "@fluentui/react-components";
import { MessageBar, Label, mergeStyles, IconButton } from '@fluentui/react';
import { tagStyle, labelStyleDetails, messageBarIconStyle, messageBarDivStyle, messageBarTagsStyle, tagsDivStyle } from './react_styles_ui';
import { buttonSettingsDetailsStyle } from "../react_styles";
import { useState } from "react";

interface CustomTagProps {
    stringToParse: string;
    separator: string;
    label: string;
};

const DynamicTags = ({ stringToParse, separator, label }: CustomTagProps) => {
    // Split the string based on the separator
    const items = stringToParse.split(separator);
    const [expandedSearchQueries, setExpandedSearchQueries] = useState<boolean>(false);
    return (
        <div style={messageBarDivStyle} data-testid="DynamicTags">
            <MessageBar 
                isMultiline={true} 
                messageBarIconProps={{ iconName: 'CompletedSolid', styles: messageBarIconStyle}}
                styles={messageBarTagsStyle}
            >
                <Label styles={labelStyleDetails} >{label}</Label>
                <IconButton
                    iconProps={{ iconName: expandedSearchQueries ? 'ChevronUp' : 'ChevronDown' }}
                    onClick={() => setExpandedSearchQueries(prevVisible => !prevVisible)}
                    styles={buttonSettingsDetailsStyle}
                    data-testid="filter-button"
                />
                    {expandedSearchQueries && (
                    <div className={mergeStyles(tagsDivStyle)}>
                        {items.map((item, index) => (
                            <Tag 
                                key={index}  
                                style={tagStyle} 
                            >
                                {item.trim()}
                            </Tag>
                        ))}
                    </div>            
                )}
            </MessageBar>
        </div>
    );
};

export default DynamicTags;