import React, { useState, useEffect } from 'react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { choiceGroupStyles } from './react_styles_ui';

interface CustomChoiceGroupProps {
    options: IChoiceGroupOption[];
    selectedOption: number ;
    onChange: (option?: IChoiceGroupOption) => void;
};


const CustomChoiceGroup: React.FunctionComponent<CustomChoiceGroupProps> = ({ options, onChange, selectedOption }) => {

    // Set selected option
    const [selectedItem, setSelectedItem] = useState<IChoiceGroupOption>(options[selectedOption]);

    // Handle state change events
    const handleChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        if (option) {
            setSelectedItem(option);
            if (onChange) {
                onChange(option);
            }
        }
    };

    // Update selected items
    useEffect(() => {
        setSelectedItem(options[selectedOption]);
    }, [options[selectedOption]]);

    // Construct ChoiceGroup component
    return (
        <div>
            <ChoiceGroup
                selectedKey={selectedItem?.key}
                options={options}
                styles={choiceGroupStyles}
                onChange={handleChange}
            />
        </div>
    );
};

export default CustomChoiceGroup;