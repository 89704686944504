import React, { useState, useEffect } from 'react';
import { ComboBox, IComboBox, IComboBoxOption } from '@fluentui/react';
import { comboBoxStyles } from './react_styles_ui';

interface CustomComboBoxProps {
    options: IComboBoxOption[];
    onChange: (selectedItem: IComboBoxOption | undefined, index?: number, value?: string) => void;
    selectedOption?: IComboBoxOption;
    placeholder?: string;
};


const CustomComboBox: React.FunctionComponent<CustomComboBoxProps> = ({ options, onChange, selectedOption, placeholder }) => {

    // Initialize state 
    const [selectedItem, setSelectedItem] = useState<IComboBoxOption | undefined>(selectedOption || options[0]);

    // Handle state change events
    const handleChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        setSelectedItem(option);
        if (onChange) {
            onChange(option, index, value)
        };
    };

    // Update selected items
    useEffect(() => {
        setSelectedItem(selectedOption);
    }, [selectedOption]);

    // Construct ComboBox component
    return (
        <div>
            <ComboBox
                selectedKey={selectedItem?.key}
                text={selectedItem?.text}
                options={options}
                styles={comboBoxStyles}
                calloutProps={{ doNotLayer: true }}
                onChange={handleChange}
                placeholder={placeholder}
            />
        </div>
    );
};

export default CustomComboBox;