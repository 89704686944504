import {
  ICheckboxStyles,
  mergeStyleSets,
  mergeStyles,
  IChoiceGroupStyles,
  IComboBoxStyles,
  IStackStyles,
  IDropdownStyles,
  IDatePickerStyles,
  ISliderStyles,
  ISpinnerStyles,
} from '@fluentui/react';

export const infoLabelStyle = {
  root: {
    fontWeight: 'bold',
    fontSize: '1.0rem',
    marginBottom: '0px',
    marginRight: '5px',
    fontFamily: 'Roboto',
    color: '#003c50',
  },
};

export const buttonAddStyle = {
  root: {
    width: 'auto',
    minWidth: '40px',
    height: '30px',
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#003e52',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginBottom: 0,
    color: 'white',
  },
  rootHovered: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootPressed: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootFocused: {
    backgroundColor: '#003e52',
    outline: 'none',
    color: 'white',
  },
};

export const buttonRemoveStyle = {
  root: {
    minwidth: '40px',
    backgroundColor: '#003c50',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    paddingLeft: 1,
    paddingRight: 0,
    color: 'white',
  },
  rootHovered: {
    backgroundColor: '#003c50d4',
    outline: 'none',
    color: 'white',
  },
  rootPressed: {
    backgroundColor: '#003c50d4',
    outline: 'none',
    color: 'white',
  },
  rootFocused: {
    backgroundColor: '#003c50',
    outline: 'none',
  },
};

export const buttonRemoveAllMultiselectStyle = {
  root: {
    minwidth: '40px',
    backgroundColor: '#003c50',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    paddingLeft: 1,
    marginLeft: 20,
    paddingRight: 0,
    color: 'white',
  },
  rootHovered: {
    backgroundColor: '#003c50d4',
    outline: 'none',
    color: 'white',
  },
  rootPressed: {
    backgroundColor: '#003c50d4',
    color: 'white',
    outline: 'none',
  },
  rootFocused: {
    backgroundColor: '#003c50',
    outline: 'none',
  },
};

export const buttonRemoveMultiselectStyle = {
  root: {
    width: '10px',
    backgroundColor: '#003e52',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '4px',
    borderColor: 'transparent',
    outline: 'none',
    color: 'white',
    marginLeft: '10px',
    marginRight: '5px',
    paddingBottom: '1px',
  },
  rootHovered: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootPressed: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootFocused: {
    backgroundColor: '#003e52',
    outline: 'none',
    color: 'white',
  },
};

export const buttonEditPromptStyle = {
  root: {
    width: '30%',
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#003e52',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.3rem',
    borderColor: 'transparent',
    outline: 'none',
    marginBottom: 30,
    color: 'white',
  },
  rootHovered: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootPressed: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootFocused: {
    backgroundColor: '#003e52',
    outline: 'none',
    color: 'white',
  },
};

export const buttonModalCloseStyle = {
  root: {
    width: '40px',
    backgroundColor: '#003e52',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginBottom: 15,
    color: 'white',
  },
  rootHovered: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootPressed: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootFocused: {
    backgroundColor: '#003e52',
    outline: 'none',
    color: 'white',
  },
};

export const stackCheckbox = {
  childrenGap: 10,
};

export const checkboxStyles: Partial<ICheckboxStyles> = {
  root: {
    fontFamily: 'Roboto',
    selectors: {
      '&.is-checked .ms-Checkbox-checkbox': {
        backgroundColor: '#003c50',
        borderColor: '#003c50',
      },
      '&:hover .ms-Checkbox-checkbox': {
        backgroundColor: '#003c50',
        borderColor: '#003c50',
        color: '#ffffff',
      },
      '&:hover .ms-Checkbox-checkmark': {
        color: '#ffffff',
      },
    },
  },
  checkbox: {
    borderColor: '#ccc',
    backgroundColor: 'white',
    borderRadius: '0.4rem',
    ':hover:': {
      color: '#ffffff !important',
    },
  },
};

export const stylesDatePickerDiv = mergeStyleSets({
  root: {
    selectors: {
      '> *': { marginBottom: 15 },
    },
    color: '#003c50',
  },
});

export const datePickerStyles: Partial<IDatePickerStyles> = {
  root: {
    maxWidth: 120,
    fontFamily: 'Roboto',
    borderRadius: '0.5rem',
    borderColor: 'transparent !important',
    marginTop: 5,
    marginBottom: 15,
  },
  textField: {
    selectors: {
      '.ms-TextField-fieldGroup': {
        border: 'none',
      },
      '.ms-TextField-fieldGroup::after': {
        border: 'none',
      },
      '::after': {
        borderRadius: '0.5rem !important',
        border: '1px solid #ccc !important',
        content: '""',
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
    },
  },
};

export const choiceGroupStyles: Partial<IChoiceGroupStyles> = {
  root: {
    maxWidth: 300,
    fontFamily: 'Roboto',
    marginBottom: 10,
    color: '#003c50  !important',
    selectors: {
      '.ms-ChoiceField-field::before': {
        color: '#003c50',
        borderColor: '#ccc !important',
      },
      '.ms-ChoiceField-field::after': {
        backgroundColor: '#003c50',
        borderColor: '#003c50 !important',
      },
      ':hover .ms-ChoiceField-field': {
        color: '#003c50  !important',
      },
      ':hover .ms-ChoiceField.root': {
        color: '#003c50  !important',
      },
      ':hover': {
        color: '#003c50  !important',
      },
    },
  },
};

export const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: {
    width: 300,
    fontFamily: 'Roboto',
    borderRadius: '0.5rem',
    color: '#003c50',
    selectors: {
      '::after': {
        borderRadius: '0.5rem !important',
        border: '1px solid #ccc !important',
        content: '""',
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
      '.ms-ComboBox-Input:focus ~ ::after': {
        borderColor: 'transparent !important',
      },
      '.ms-ComboBox-CaretDown-button': {
        borderRadius: '0.5rem',
        backgroundColor: '#ffffff',
        color: '#003c50',
        selectors: {
          ':hover': {
            backgroundColor: '#ffffff',
            color: '#000000',
          },
          ':focus': {
            backgroundColor: '#ffffff',
            outline: 'none',
            color: '#000000',
          },
        },
      },
    },
  },
  header: {
    color: '#003c50',
    fontWeight: 'bold',
  },
};

export const stackDynamicItems = {
  childrenGap: 10,
};

export const stackDynamicSingleItem: Partial<IStackStyles> = {
  root: {
    width: '100%',
    marginBottom: 15,
    display: 'flex',
  },
};

export const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: {
    width: 275,
    fontFamily: 'Roboto',
    borderRadius: '0.5rem',
    color: '#003c50',
    position: 'relative',
    selectors: {
      '::after': {
        borderRadius: '0.5rem !important',
        border: '1px solid #ccc !important',
        content: '""',
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
      '.ms-Dropdown-caretDownWrapper': {
        borderRadius: '0.5rem',
        backgroundColor: '#ffffff',
        color: '#003c50',
        selectors: {
          ':hover': {
            color: '#000000',
          },
          ':focus': {
            outline: 'none',
            color: '#000000',
          },
        },
      },
    },
  },
  title: {
    borderColor: 'transparent !important',
  },
};

export const dropdownStylesSkills: Partial<IDropdownStyles> = {
  dropdown: {
    width: 100,
    fontFamily: 'Roboto',
    borderRadius: '0.5rem',
    color: '#003c50',
    position: 'relative',
    selectors: {
      '::after': {
        borderRadius: '0.5rem !important',
        border: '1px solid #ccc !important',
        content: '""',
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
      '.ms-Dropdown-caretDownWrapper': {
        borderRadius: '0.5rem',
        backgroundColor: '#ffffff',
        color: '#003c50',
        selectors: {
          ':hover': {
            color: '#000000',
          },
          ':focus': {
            outline: 'none',
            color: '#000000',
          },
        },
      },
    },
  },
  title: {
    borderColor: 'transparent !important',
  },
};

export const stackDynamicSinglePromptItem: Partial<IStackStyles> = {
  root: {
    width: '100%',
    marginBottom: 15,
    display: 'block',
  },
};

export const itemStyle = {
  root: {
    width: 200,
  },
  fieldGroup: {
    color: '#003c50',
    borderRadius: '0.5rem',
    border: '1px solid #ccc',
    padding: '2px',
    selectors: {
      ':hover': {
        borderColor: '#003c50',
      },
      ':focus': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      ':focus-within': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      '::after': {
        borderRadius: '0.5rem !important',
        border: '2px solid #003c50 !important',
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
    },
  },
  field: {
    fontFamily: 'Roboto',
    resize: 'vertical',
  },
};

export const getSliderStyles = (
  sliderValue: number
): Partial<ISliderStyles> => ({
  root: {
    width: 300,
    fontFamily: 'Roboto',
  },
  slideBox: {
    '&:hover .ms-Slider-thumb': {
      borderColor: `rgba(240, 125, 0, ${sliderValue / 3})`,
    },
    '&:hover .ms-Slider-active': {
      backgroundColor: `rgba(240, 125, 0, ${sliderValue / 3})`,
    },
  },
  thumb: {
    backgroundColor: '#F07D00',
    borderColor: '#F07D00',
  },
  activeSection: {
    backgroundColor: `rgba(240, 125, 0, ${sliderValue / 3})`,
    borderColor: `rgba(240, 125, 0, ${sliderValue / 3})`,
    color: '#F07D00',
  },
  valueLabel: {
    fontSize: '12px',
    paddingLeft: '8px',
    overflowWrap: 'break-word',
    justifyContent: 'center',
    display: 'flex',
  },
});

export const tagStyle = {
  display: 'flex',
  backgroundColor: '#003e52',
  marginRight: '10px',
  marginBottom: '10px',
  borderRadius: '0.5rem',
  fontFamily: 'Roboto',
  color: 'white',
};

export const tagMultiselectStyle = {
  display: 'flex',
  backgroundColor: '#003e52',
  borderRadius: '0.5rem',
  fontFamily: 'Roboto',
  color: 'white',
  alignItems: 'center',
};

export const tagMultiselectDivStyle = {
  root: {
    backgroundColor: '#add8e657',
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    marginTop: '15px',
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '0.5rem',
  },
};

export const textFieldPromptSearchSetting = {
  root: {
    width: '100%',
    fontFamily: 'Roboto',
    borderRadius: '4px',
    display: 'block',
  },
  field: {
    fontFamily: 'Roboto',
    color: '#003c50',
    resize: 'vertical',
  },
  fieldGroup: {
    color: '#003c50',
    borderRadius: '0.5rem',
    border: '1px solid #ccc',
    padding: '2px',
    selectors: {
      ':hover': {
        borderColor: '#003c50',
      },
      ':focus': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      ':focus-within': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      '::after': {
        borderRadius: '0.5rem !important',
        border: '2px solid #003c50 !important',
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
    },
  },
  subComponentStyles: {
    label: {
      root: {
        selectors: {
          ':focus-within': {
            color: '#639aaf',
            borderRadius: '0.5rem',
          },
        },
      },
    },
  },
};

export const labelStyle = {
  root: {
    fontWeight: 'bold',
    fontSize: '1.0rem',
    marginBottom: '8px',
    fontFamily: 'Roboto',
    color: '#003c50',
  },
};

export const labelStyleDetails = {
  root: {
    color: '#003c50',
  },
};

export const labelStyleDeleteConfirmationModal = {
  root: {
    color: '#003c50',
    fontSize: '1.1rem',
    fontFamily: 'Roboto',
  },
};

export const labelStyleDeleteConfirmationModalSetting = {
  root: {
    color: '#003c50',
    fontSize: '1rem',
    fontFamily: 'Roboto',
    marginBottom: '30px',
    marginTop: '30px',
    fontWeight: '400',
  },
};

export const labelStyleChatAssistant = {
  root: {
    fontFamily: 'Roboto',
    marginRight: 10,
    paddingTop: 10,
  },
};

export const chatDiv = {
  display: 'block',
  marginTop: '20px',
  paddingRight: '30px',
  marginBottom: '20px',
  width: '100%',
  paddingLeft: '30px',
  backgroundColor: '#dce5e730',
  padding: '10px',
  border: '1px solid #e5e7eb',
  borderRadius: '0.5rem',
  maxHeight: '500px',
  overflowY: 'auto',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.30),0px 0px 2px rgba(0, 0, 0, 0.12)',
};

export const buttonStyleResetChat = {
  root: {
    width: '10%',
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#F07D00',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginLeft: 'auto',
    marginRight: '0px',
  },
  rootHovered: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootFocused: {
    backgroundColor: '#F07D00',
    outline: 'none',
    borderColor: 'black',
  },
};

export const buttonStyleConfirmDelete = {
  root: {
    width: '10%',
    fontSize: '11px',
    fontWeight: '400',
    backgroundColor: '#F07D00',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginLeft: 'auto',
    marginRight: '0px',
  },
  rootHovered: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootFocused: {
    backgroundColor: '#F07D00',
    outline: 'none',
  },
};

export const buttonStyleCancelDelete = {
  root: {
    minwidth: '40px',
    fontSize: '11px',
    fontWeight: '400',
    backgroundColor: '#003c50',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    color: 'white',
    marginLeft: '0px',
  },
  rootHovered: {
    backgroundColor: '#003c50d4',
    outline: 'none',
    color: 'white',
  },
  rootPressed: {
    backgroundColor: '#003c50d4',
    outline: 'none',
    color: 'white',
  },
  rootFocused: {
    backgroundColor: '#003c50',
    outline: 'none',
  },
};

export const stackChatField = {
  root: {
    width: '100%',
    paddingRight: '20px',
    overflowY: 'auto',
  },
};

export const stackExpanderField = {
  root: {
    width: '100%',
    marginTop: '21px',
  },
};

export const chatTextFieldStackStyle = {
  root: {
    marginBottom: '10px',
    borderRadius: '.5rem',
    width: '100%',
  },
};

export const textFieldStyleChat = {
  fieldGroup: {
    color: '#003c50',
    borderRadius: '0.5rem',
    border: '1px solid #ccc',
    padding: '2px',
    selectors: {
      ':hover': {
        borderColor: '#003c50',
      },
      ':focus': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      ':focus-within': {
        borderColor: '#003c50',
        borderRadius: '0.5rem',
      },
      '::after': {
        borderRadius: '0.5rem !important',
        border: '2px solid #003c50 !important',
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
    },
  },
  root: {
    width: '100%',
  },
  field: {
    fontFamily: 'Roboto',
    color: '#003c50',
    resize: 'vertical',
  },
  subComponentStyles: {
    label: {
      root: {
        selectors: {
          ':focus-within': {
            color: '#639aaf',
            borderRadius: '0.5rem',
          },
        },
      },
    },
  },
};

export const buttonStyleChat = {
  root: {
    width: '100%',
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#F07D00',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginLeft: '20px',
  },
  rootHovered: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootFocused: {
    backgroundColor: '#F07D00',
    outline: 'none',
    borderColor: 'black',
  },
};

export const buttonChatExpanderStyle = {
  root: {
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#003e52',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginBottom: 30,
    marginRight: 10,
    color: 'white',
  },
  rootHovered: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootPressed: {
    backgroundColor: '#003e52db',
    outline: 'none',
    color: 'white',
  },
  rootFocused: {
    backgroundColor: '#003e52',
    outline: 'none',
    color: 'white',
  },
};

export const buttonStyleShowCV = {
  root: {
    width: '200px',
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#F07D00',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginTop: '20px',
    marginBottom: '20px',
    marginRight: '20px',
  },
  rootHovered: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootFocused: {
    backgroundColor: '#F07D00',
    outline: 'none',
    borderColor: 'black',
  },
};

export const buttonStyleSecureGPT = {
  root: {
    width: '200px',
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#F07D00',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '0.5rem',
    borderColor: 'transparent',
    outline: 'none',
    marginBottom: '20px',
    marginLeft: '20px',
  },
  rootHovered: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootFocused: {
    backgroundColor: '#F07D00',
    outline: 'none',
    borderColor: 'black',
  },
};

export const buttonStyleShowPDF = {
  root: {
    width: '7%',
    fontSize: '11px',
    fontWeight: '500',
    backgroundColor: '#F07D00',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    letterSpacing: '0.02857em',
    verticalAlign: 'middle',
    borderRadius: '4px',
    borderColor: 'transparent',
    outline: 'none',
    marginTop: '30px',
    marginLeft: '20px',
    color: 'white',
    marginBottom: '200px',
  },
  rootHovered: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
    color: 'white',
  },
  rootPressed: {
    backgroundColor: '#D06900',
    outline: 'none',
    borderColor: 'transparent',
    color: 'white',
  },
  rootFocused: {
    backgroundColor: '#F07D00',
    outline: 'none',
    borderColor: 'black',
    color: 'white',
  },
};

export const messageBarIconStyle = {
  root: {
    marginTop: '5px',
    color: '#003e52',
  },
};

export const messageBarDivStyle = {
  marginBottom: '30px',
  marginTop: '10px',
};

export const spinnerStyleChat: Partial<ISpinnerStyles> = {
  circle: {
    borderTopColor: '#F07D00',
    borderRightColor: '#f07d004d',
    borderLeftColor: '#f07d004d',
    borderBottomColor: '#f07d004d',
  },
  label: {
    color: '#F07D00',
  },
};

export const chatTextFieldItem = {
  root: {
    width: '90%',
  },
};

export const chatButtonItem = {
  root: {
    width: '10%',
  },
};

export const messageBarTagsStyle = {
  root: {
    background: '#add8e657',
    borderRadius: '0.5rem',
    fontFamily: 'Roboto',
    overflowY: 'auto',
    color: '#003c50',
  },
};

export const tagsDivStyle = {
  display: 'flex',
  flexWrap: 'wrap',
};

export const userMessageBaseStyle = mergeStyles({
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  margin: '10px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  border: '1px solid #e5e7eb',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14),0px 0px 2px rgba(0, 0, 0, 0.12)',
  outline: 'transparent solid 1px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '22px',
  marginLeft: 'auto',
  display: 'block',
  maxWidth: '80%',
  width: 'auto',
  color: '#003c50',
});

export const assistantFirstMessageStyle = mergeStyles({
  backgroundColor: '#003c50',
  padding: '20px',
  borderRadius: '0.5rem',
  margin: '5px 0',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  border: '1px solid #e5e7eb',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14),0px 0px 2px rgba(0, 0, 0, 0.12)',
  outline: 'transparent solid 1px',
  fontSize: '14.5px',
  fontWeight: '400',
  lineHeight: '22px',
  display: 'flex',
  maxWidth: '100%',
  color: 'white',
});

export const assistantMessageBaseStyle = mergeStyles({
  backgroundColor: '#003c50',
  padding: '20px',
  borderRadius: '8px',
  margin: '10px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  border: '1px solid #e5e7eb',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14),0px 0px 2px rgba(0, 0, 0, 0.12)',
  outline: 'transparent solid 1px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '22px',
  display: 'block',
  maxWidth: '80%',
  width: 'auto',
  color: 'white',
});

export const chatRoleLabelStyle = mergeStyles({
  fontWeight: 'bold',
  marginBottom: '5px',
});

export const dataStatisticsStyle = mergeStyles({
  backgroundColor: '#003c50',
  padding: '20px',
  borderRadius: '0.5rem',
  margin: '5px 0',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  border: '1px solid #e5e7eb',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14),0px 0px 2px rgba(0, 0, 0, 0.12)',
  outline: 'transparent solid 1px',
  fontSize: '14.5px',
  fontWeight: '400',
  lineHeight: '22px',
  display: 'flex',
  maxWidth: '100%',
  color: 'white',
});

export const toolTipStyle = mergeStyles({
  position: 'absolute',
  bottom: '-160%',
  left: '0%',
  backgroundColor: 'white',
  color: 'black',
  padding: '5px',
  borderRadius: '3px',
  width: '350px',
  textAlign: 'left',
  zIndex: 1000,
  fontSize: '0.8rem',
  fontFamily: 'Roboto',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.30),0px 0px 2px rgba(0, 0, 0, 0.12)',
  overflowY: 'auto',
  maxHeight: '80px',
  transform: 'translateX(0%)',
});

export const toolTipIconStyle = mergeStyles({
  display: 'inline-block',
  marginLeft: '5px',
  cursor: 'pointer',
});

export const infoLabelDiv = mergeStyles({
  position: 'relative',
  display: 'flex',
});

export const dataTableModalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '70%',
    backgroundColor: 'white',
    overflow: 'hidden',
    paddingBottom: '80px',
    borderRadius: '0.5rem',
  },
};

export const confirmDeleteDialogStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    height: '190px',
    backgroundColor: 'white',
    overflow: 'hidden',
    borderRadius: '0.5rem',
  },
};

export const modalContentDiv = mergeStyles({
  height: '100%',
  overflowY: 'auto',
  paddingTop: '20px',
  paddingBottom: '50px',
});

export const cvDataDiv = mergeStyles({
  height: '600px',
  width: '100%',
  overflow: 'auto',
});

export const cvDataAgGridDiv = mergeStyles({
  height: '100%',
  width: '100%',
  paddingTop: '20px',
});

export const chatAssistantStack = {
  root: {
    marginLeft: 'auto',
    marginTop: '20px',
  },
};

export const chatAssistantCombobox: Partial<IComboBoxStyles> = {
  root: {
    maxWidth: 500,
    fontFamily: 'Roboto',
    borderRadius: '0.5rem',
    color: '#003c50',
    selectors: {
      '::after': {
        borderRadius: '0.5rem !important',
        border: '1px solid #ccc !important',
        content: '""',
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      ':hover::after': {
        borderColor: '#003c50 !important',
      },
      ':focus-within::after': {
        borderColor: '#003c50 !important',
      },
      ':focus::after': {
        borderColor: '#003c50 !important',
      },
      '.ms-ComboBox-Input:focus ~ ::after': {
        borderColor: 'transparent !important',
      },
      '.ms-ComboBox-CaretDown-button': {
        borderRadius: '0.5rem',
        backgroundColor: '#ffffff',
        color: '#003c50',
        selectors: {
          ':hover': {
            backgroundColor: '#ffffff',
            color: '#000000',
          },
          ':focus': {
            backgroundColor: '#ffffff',
            outline: 'none',
            color: '#000000',
          },
        },
      },
    },
  },
};

export const buttonStyleLogout = {
  root: {
    fontSize: '0.876rem',
    backgroundColor: '#003c50',
    fontFamily: 'Roboto',
    verticalAlign: 'middle',
    borderColor: 'transparent',
    outline: 'none',
    '.ms-Button-label': {
      fontWeight: '400',
      letterSpacing: '0.02757rem',
    },
  },
  rootHovered: {
    backgroundColor: '#003c50',
    outline: 'none',
    borderColor: 'transparent',
  },
  rootPressed: {
    backgroundColor: '#003c50',
    outline: 'none',
    borderColor: 'transparent',
  },
};
