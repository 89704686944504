import { API_BASE_URL } from '../api/api_base_url';
import React, { createContext, useContext, useEffect, useState, ReactNode, useMemo, useCallback } from 'react';
import { PublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { msalConfig, setMsalConfig } from './auth_config';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

// Good to know: Authentication process (https://entra.microsoft.com/#home) > Applications > Enterprise applications/ App registrations
// - Roles have to be defined in Microsoft Entra Id > Registered Apps > Manage > App roles
// - Admin consent has to be granted for tenant in Microsoft Entra Id > Registered Apps > Manage > API permissions
// - Single-page application platform has to be created with specific Redirect URIs in Microsoft Entra Id > Registered Apps > Manage > Authentication
// - When adding new users and groups in Microsoft Entra ID > Enterprise Application > Manage > Users and groups a pre-defined role has to be selected
// - Currently, the role profile is hard coded, i. e. if the role is advanced, certain settings are additionally shown
// - Role profiles can be created and stored in environmental variables and retrieved from the backend, the frontend needs to be adjuste then (if needed)

export interface User {
  userName: string | undefined;
  email: string | undefined;
  role: string;
}

export interface AuthContextType {
  user: User | null;
  login: () => void;
  logout: () => void;
  isAllowedRole: (role: string) => boolean;
  checkPermission: (role: string, permission: string) => Promise<boolean>;

}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  login: () => { },
  logout: () => { },
  checkPermission: async () => false,
  isAllowedRole: () => false,
});

export const useAuth = () => useContext(AuthContext);

export const fetchAuthData = async () => {
  try {
    const { data } = await axios.post(`${API_BASE_URL}/api/auth_data`);
    return {
      clientId: data.clientId,
      authority: data.authority,
      redirectUri: data.redirectUri,
      grant_type: data.grant_type,
      postLogoutRedirectUri: '/',
      allowedRoles: [data.advanced_role_name, data.simple_role_name, data.admin_role_name]
    };
  } catch (error) {
    console.error('Error fetching auth data:', error);
    return null;
  }
};

export const isTokenExpired = (token: string): boolean => {
  try {
    const decodedToken: any = jwtDecode(token);
    const exp = decodedToken?.exp;
    if (!exp) {
      console.error('Token does not contain exp field');
      return true;
    }
    const currentTime = Math.floor(Date.now() / 1000);
    return exp + 6000 < currentTime;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};


export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  // Initialize states and constants
  const [user, setUser] = useState<User | null>(null);
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication | null>(null);
  const [allowedRoles, setAllowedRoles] = useState<string[]>([]);

  // Update MSAL initialization
  useEffect(() => {
    const initialize = async () => {
      const fetchedAuthData = await fetchAuthData();
      if (fetchedAuthData) {
        setAllowedRoles(fetchedAuthData.allowedRoles);
        setMsalConfig(fetchedAuthData);
        const instance = new PublicClientApplication(msalConfig);
        setMsalInstance(instance);
        await initializeMsal(instance);
      }
    };
    initialize();
  }, []);

  // Periodically check for token expiration (every 60 seconds)
  // useEffect(() => {
  //   const interval = setInterval(async () => {
  //     if (user && msalInstance) {
  //       const accounts = msalInstance.getAllAccounts();
  //       if (accounts.length > 0) {
  //         const response = await msalInstance.acquireTokenSilent({
  //           account: accounts[0],
  //           scopes: ['user.read'],
  //         });
  //         const idToken = response?.idToken;
  //         if (idToken && isTokenExpired(idToken)) {
  //           //setUser(null); // add logic when token expires (if necessary, maybe when settings can be saved and loaded)
  //         }
  //       }
  //     }
  //   }, 60000);
  //   return () => clearInterval(interval);
  // }, [user, msalInstance]);

  // Function to initialize MSAL instance
  const initializeMsal = async (msalInstance: PublicClientApplication) => {
    try {
      await msalInstance.initialize();
    } catch (error) {
      console.error('Error initializing MSAL:', error);
    }
  };

  // Function to fetch user data from app specific Microsoft Entra ID
  const fetchUserData = useCallback(async (account: AccountInfo) => {
    const { idTokenClaims } = account;
    try {
      const response = await msalInstance?.acquireTokenSilent({
        account: account,
        scopes: ['user.read'],
      });
      const idToken = response?.idToken;
      if (!idToken) {
        throw new Error('ID token is undefined or empty.');
      }
      // add logic here when token expires like: if (isTokenExpired(idToken)) setUser(null) return; (if necessary)
      const decodedToken: any = jwtDecode(idToken);
      const roles: string[] = decodedToken.roles || [];
      let roleName = '';
      if (roles.includes(allowedRoles[0])) {
        roleName = allowedRoles[0];
      } else if (roles.length > 0) {
        roleName = roles[0];
      }
      const newUser: User = {
        userName: idTokenClaims?.name ?? '',
        email: idTokenClaims?.preferred_username ?? '',
        role: roleName,
      };
      setUser(newUser);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [allowedRoles, msalInstance]);

  // Update redirect based on MSAL instance
  useEffect(() => {
    const handleRedirect = async () => {
      if (msalInstance) {
        try {
          const response = await msalInstance.handleRedirectPromise();
          if (response) {
            const account = response.account;
            if (account) {
              await fetchUserData(account);
            }
          } else {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
              await fetchUserData(accounts[0]);
            }
          }
        } catch (error) {
          console.error('Error handling redirect:', error);
        }
      }
    };

    handleRedirect();
  }, [msalInstance, fetchUserData]);


  // Function for login
  const login = useCallback(async () => {
    if (msalInstance) {
      try {
        await msalInstance.loginRedirect();
      } catch (error) {
        console.error('Login error:', error);
      }
    }
  }, [msalInstance]);

  // Function for logout
  const logout = useCallback(async () => {
    if (msalInstance) {
      try {
        await msalInstance.logoutRedirect();
        setUser(null);
      } catch (error) {
        console.error('Logout error:', error);
      }
    }
  }, [msalInstance]);

// Memorize context values and update only when a dependency changes
const contextValue = useMemo(() => {
  const isAllowedRole = (role: string) => allowedRoles.includes(role);

  return {
    user,
    login,
    logout,
    checkPermission,
    isAllowedRole,
  };
}, [user, login, logout, allowedRoles]);

  // Construct AuthContext component
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// Function to check permissions
export const checkPermission = async (role: string, permission: string): Promise<boolean> => {
  try {
    // console.log(`Checking permission for role: ${role}, permission: ${permission}`);

    const { data } = await axios.get(`${API_BASE_URL}/api/permissions/${role}/${permission}`);

   // console.log('API response:', data);
   // console.log('Parsed response has_permission:', data.has_permission);

    return data.has_permission;
  } catch (error) {
    console.error('Error in checkPermission function:', error);
    return false;
  }
};
