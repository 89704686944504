import { API_BASE_URL } from './api_base_url';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import { SearchSettings } from '../SearchComponent';


// API functions for backend calls for search settings handling

export const getUserSettingsNames = async (userId: string | undefined, savedSearchSettings: IComboBoxOption[]) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/all_settings_names`, { userId });
        const settingsNames = response.data;
        const fetchedOptions: IComboBoxOption[] = settingsNames.map((name: any, index: any) => ({
            key: `${index}`,
            text: name
        }));
        const optionsWithHeaders: IComboBoxOption[] = [
            { key: 'Header1', text: 'Fetched Settings', itemType: SelectableOptionMenuItemType.Header },
            ...fetchedOptions,
            { key: 'Header2', text: 'Saved Search Settings', itemType: SelectableOptionMenuItemType.Header },
            ...savedSearchSettings,
        ];
        const sortedOptions = optionsWithHeaders
            .filter(option => option.itemType !== SelectableOptionMenuItemType.Header)
            .sort((a, b) => a.text.localeCompare(b.text));
        const combinedOptions = [
            { key: 'Header1', text: 'Your saved settings', itemType: SelectableOptionMenuItemType.Header },
            ...sortedOptions.filter(option => fetchedOptions.some(o => o.text === option.text)),
            { key: 'Header2', text: 'Use cases', itemType: SelectableOptionMenuItemType.Header },
            ...sortedOptions.filter(option => savedSearchSettings.some(o => o.text === option.text))
        ];
        return combinedOptions;
    } catch (error) {
        console.error('Error fetching all user settings names:', error);
        toast.error(`Error fetching all user settings names!`);
        return savedSearchSettings;
    }
};


export const loadUserSettings = async (userId: string | undefined, settingsName: string | undefined, defaultSettings: SearchSettings) => {
    try {
        const response = await axios.post<SearchSettings>(`${API_BASE_URL}/api/load_settings`, {
            userId: userId,
            settingsName: settingsName
        });
        const userSettings = response.data;
        const convertedSettings: SearchSettings = {
            ...userSettings,
            avDateMin: new Date(userSettings.avDateMin),
            avDateMax: new Date(userSettings.avDateMax),
            projectStartDate: new Date(userSettings.projectStartDate),
            projectEndDate: new Date(userSettings.projectEndDate),
            entryDateAfter: new Date(userSettings.entryDateAfter),
            lastModifiedDateOlder: new Date(userSettings.lastModifiedDateOlder)
        };
        return { userSearchSettings: convertedSettings, errorLoading: false };
    } catch (error) {
        console.error('Error in loading saved search settings:', error);
        toast.error(`Error loading setting "${settingsName}"!`);
        return { userSearchSettings: defaultSettings, errorLoading: true };
    }
};


export const deleteUserSettings = async (userId: string | undefined, settingsName: string | undefined) => {
    try {
        await axios.post(`${API_BASE_URL}/api/delete_settings`, { userId, settingsName });
        toast.success(`Your setting "${settingsName}" has been successfully deleted!`);
        return { errorDelete: false };
    } catch (error) {
        console.error('Error in deleting search settings:', error);
        toast.error(`Failed to delete "${settingsName}"!`);
        return { errorDelete: true };
    }
};


export const saveUserSettings = async (userId: string | undefined, settingsName: string, settings: any) => {
    try {
        await axios.post(`${API_BASE_URL}/api/save_settings`, { userId, settingsName, settings });
        toast.success(`Your setting "${settingsName}" has been successfully saved!`);
    } catch (error) {
        console.error('Error in saving search settings:', error);
        toast.error(`Failed to save "${settingsName}"!`);
    }
};