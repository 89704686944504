import { mergeStyles } from '@fluentui/react';


export const loginContainer = mergeStyles({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh', 
    backgroundColor: '#fff',
    }
  );
  
  
  export const loginBox = mergeStyles({
    padding: '1.5rem',
    border: '1px solid #e4e4e7', 
    borderRadius: '0.5rem', 
    backgroundColor: '#fff', 
    boxShadow: '0 1px 2px #0000000d', 
    textAlign: 'center',
    }
  );
  
  
  export const loginSvgStyle = mergeStyles({
    width: '2.5rem',
    height: '2rem',
    border: '1px solid #e4e4e7',
    borderRadius: '9999px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginRight: '10px'
    }
  );
  
  
  export const loginSvgStyleLogo = mergeStyles({
    width: '15rem',
    marginTop: '15px',
    marginLeft: '30px',
    }
  );
  
  
  export const labelStyleLogin = {
    root: { 
      fontWeight: '600', 
      fontSize: '1.5rem', 
      fontFamily: 'Roboto',
      color: '#003c50',
     }
  };
  
  
  export const labelStyleApp = {
    root: { 
      fontWeight: '600', 
      fontSize: '1.5rem', 
      fontFamily: 'Roboto',
      color: '#003c50',
      marginLeft: 'auto',
      marginRight: '30px',
      marginTop: '35px',
     }
  };
  
  
  export const labelStyleAccessDenied = {
    root: { 
      fontWeight: '600', 
      fontSize: '1.5rem', 
      fontFamily: 'Roboto',
      color: '#A80000',
      marginTop: '15px',
      marginBottom: '15px'
     }
  };
  
  
  export const stackLogin = { 
    root: { 
        width: '100%', 
    } 
  };
  
  export const loginTextStyle = mergeStyles({
    fontSize: '1rem', 
    lineHeight: '1.25rem',
    fontWeight:'450',
    }
  );
  
  export const loginDeniedTextStyle = mergeStyles({
    fontSize: '1rem', 
    lineHeight: '1.25rem',
    fontWeight:'600',
    color: '#003c50',
    marginTop: '30px',
    marginBottom: '30px'
    }
  );
  
  export const buttonStyleLogin = {
    root: {
        width: '100%',
        fontSize: '0.876rem', 
        backgroundColor: '#003c50',
        fontFamily: 'Roboto',
        verticalAlign: 'middle',
        borderRadius: '0.5rem',
        borderColor: 'transparent',
        outline: 'none',
        paddingTop: '20px',
        paddingBottom: '20px',
        marginTop: '20px',
        '.ms-Button-label': {
          fontWeight: '400',
          letterSpacing: '0.02757rem',
        },
    },
    rootHovered: {
        backgroundColor: '#003c50d4',
        outline: 'none',
        borderColor: 'transparent'
    },
    rootPressed: {
        backgroundColor: '#003c50',
        outline: 'none',
        borderColor: 'transparent'
    },
  };
  
  
  export const buttonStyleLoginCloseModal = {
    root: {
        width: '20%',
        fontSize: '0.876rem', 
        backgroundColor: '#003c50',
        fontFamily: 'Roboto',
        verticalAlign: 'middle',
        borderRadius: '0.5rem',
        borderColor: 'transparent',
        textTransform: 'uppercase',
        outline: 'none',
        '.ms-Button-label': {
          fontWeight: '400',
          letterSpacing: '0.02757rem',
        },
    },
    rootHovered: {
        backgroundColor: '#003c50d4',
        outline: 'none',
        borderColor: 'transparent'
    },
    rootPressed: {
        backgroundColor: '#003c50',
        outline: 'none',
        borderColor: 'transparent'
    },
  };
  
  
  export const loginDeniedModalStyle = {
    overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
    },
    content: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '450px',
      height: '220px',
      backgroundColor: '#FDE7E9',
      overflow: 'auto',
      paddingBottom: '20px',
      borderRadius: '0.5rem',
    },
  };
  