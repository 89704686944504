import React from 'react';
import { PrimaryButton } from '@fluentui/react';
import { useAuth } from '../auth/auth_context';
import { buttonStyleLogout } from './react_styles_ui';

interface LogoutProps {
  onLogout: () => void;
}


const Logout: React.FC<LogoutProps> = ({ onLogout }) => {

  // Get user auth data
  const { user } = useAuth();

  // Construct Logout component
  return (
    <PrimaryButton
      iconProps={{ 
        iconName:  'SignOut',
        styles: {
          root: {
            transform: 'scaleX(-1)',
            borderRadius: '0.5rem'
          },
        },
      }}
      onClick={onLogout}
      ariaLabel="Logout"
      styles={buttonStyleLogout}
      data-testid="logout-button"
  > Logout ({user?.role})
  </PrimaryButton>
  );
};

export default Logout;