import { LogLevel } from "@azure/msal-browser";


// MSAL configuration
export const msalConfig: any = {
    auth: {
      clientId: '',
      authority: '',
      redirectUri: '',
      grant_type: '',
      postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: "sessionStorage", 
        storeAuthStateInCookie: false, 
    },
    system: {	
        loggerOptions: {	
            loggerCallback: (level: any, message: any, containsPii : any) => {	
                if (containsPii) {		
                    return;		
                }		
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }	
            }	
        }	
    }
};


// Set MSAL configuration function
export const setMsalConfig = (authData: any) => {
    msalConfig.auth.clientId = authData.clientId;
    msalConfig.auth.authority = authData.authority;
    msalConfig.auth.redirectUri = authData.redirectUri;
    msalConfig.grant_type = authData.grant_type;
    msalConfig.postLogoutRedirectUri = '/';
};