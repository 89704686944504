import React, { useState, useEffect, useCallback } from 'react';
import { DatePicker } from '@fluentui/react';
import { stylesDatePickerDiv, datePickerStyles } from './react_styles_ui';

interface DatePickerModuleProps {
    onSelectDate?: (selectedDate: Date | null | undefined) => void;
    defaultDate: Date;
};

// Format date to a string to display in the DatePicker
const onFormatDate = (date?: Date): string => {
    return !date ? '' : date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + (date.getFullYear());
};


const CustomDatePicker: React.FunctionComponent<DatePickerModuleProps> = ({ onSelectDate, defaultDate }) => {
    
    // Use defaultDate if provided, otherwise use the current date
    const [selectedDate, setSelectedDate] = useState<Date | undefined>( defaultDate || new Date() );

    // Handle change events via callback
    const onDateChange = useCallback((date?: Date | null | undefined) => {
        setSelectedDate(date || undefined);
        if (onSelectDate) {onSelectDate(date)};
    }, [onSelectDate]);

    // Parse date from string in format DD.MM.YYYY
    const onParseDateFromString = (value: string): Date => {
        const dateFormatRegex = /^(\d{2})\.(\d{2})\.(\d{4})$/;
        if (dateFormatRegex.test(value)) {
            const [, day, month, year] = value.match(dateFormatRegex) || [];
            const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
            return date;
        }
        alert('Please enter a date using the format DD.MM.YYYY');
        return defaultDate;
    };

    // Update selected date
    useEffect(() => {
        setSelectedDate(defaultDate);
    }, [defaultDate]);

    // Construct 
    return (
        <div className={stylesDatePickerDiv.root}>
            <DatePicker
                value={selectedDate}
                onSelectDate={onDateChange}
                parseDateFromString={onParseDateFromString}
                formatDate={onFormatDate}
                styles={datePickerStyles}
                allowTextInput
            />
        </div>
    );
};

export default CustomDatePicker;