import { FC, useState  } from 'react';
import { PrimaryButton, Label, ComboBox, Stack, IComboBoxOption  } from '@fluentui/react';
import { buttonStyleSecureGPT, labelStyleChatAssistant, chatAssistantStack, chatAssistantCombobox } from "./react_styles_ui";

interface Props {
    rowData: Record<string, any>;
};


const ChatAssistantLink: FC<Props> = ({ rowData }) => {

  // Initialize options and state 
  const columnOptions: IComboBoxOption[] = [  
    { key: 'Expertise_html', text: 'Expertise' },
    { key: 'Industry_html', text: 'Industry' },
    { key: 'Skills_html', text: 'Skills' },
    { key: 'TenderCVs_html', text: 'Tender CVs' },
    { key: 'RelevantWorkExperiences_DE_html', text: 'Work Experience (DE)' },
    { key: 'RelevantWorkExperiences_EN_html', text: 'Work Experience (EN)' },
  ];    
  const [selectedKey, setSelectedKey] = useState<string | undefined>('RelevantWorkExperiences_DE_html');

  // Handle state change events
  const handleButtonClick = async () => {
    if (selectedKey) {
      let selectedItem = rowData[selectedKey];
      try {
        // Use regular expression to replace HTML tags and special characters
        selectedItem = selectedItem.replace(/<(a|div)[^>]*>.*?<\/\1>/gs, '');
        selectedItem = selectedItem.replace(/\.styled-button(:hover|:active|:focus)?\s*{[^}]*}/g, '');
        selectedItem = selectedItem.replace(/<[^>]*>/g, ' ');
        selectedItem = selectedItem.replace(/&#8212;/g, '-');
        selectedItem = selectedItem.replace(/&nbsp;&nbsp;&nbsp;&nbsp; &#10230/g, '-');
        
        // Attempt to copy the modified item to the clipboard
        await navigator.clipboard.writeText(selectedItem);
      } catch (error) {
        console.error('Failed to copy item to clipboard:', error);
      }
    } else {
      console.error('No item selected.');
    }
  };

  // Construct ChatAssistantLink component
  return (
    <Stack
      horizontal
      styles={chatAssistantStack}
    >
      <Label
        styles={labelStyleChatAssistant}
      >
        Copy content of:
      </Label>
      <ComboBox
        options={columnOptions}
        selectedKey={selectedKey}
        onChange={(event, option) => setSelectedKey(option?.key as string)}
        styles={chatAssistantCombobox}
      />
      <PrimaryButton
        disabled={!selectedKey}
        styles={buttonStyleSecureGPT}
        onClick={handleButtonClick}
      >
        Copy
      </PrimaryButton>
      <a href={`https://chat.d-fine.com/`} target="_blank" rel="noreferrer">
        <PrimaryButton
          disabled={!selectedKey}
          styles={buttonStyleSecureGPT}
          onClick={handleButtonClick}
        >
          Open Chat Assistant 
        </PrimaryButton>
      </a>
    </Stack>
  );
};

export default ChatAssistantLink;