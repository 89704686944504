import React from 'react';
import { Label, Stack, PrimaryButton, DefaultButton } from '@fluentui/react';
import { buttonStyleConfirmDelete, buttonStyleCancelDelete, confirmDeleteDialogStyle, labelStyleDeleteConfirmationModal, labelStyleDeleteConfirmationModalSetting } from './react_styles_ui';
import Modal from 'react-modal';

interface ConfirmationModalProps {
    isVisible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    message: string;
}

const DeleteConfirmationModal: React.FC<ConfirmationModalProps> = ({ isVisible, onConfirm, onCancel, message }) => {
    return (
        <Modal
            isOpen={isVisible}
            onRequestClose={onCancel}
            style={confirmDeleteDialogStyle}
            ariaHideApp={false}
        >
            <Label styles={labelStyleDeleteConfirmationModal}>Delete the following search setting?</Label>
            <Label styles={labelStyleDeleteConfirmationModalSetting}>{message}</Label>
            <Stack
                horizontal
            >
                <DefaultButton onClick={onCancel} text="Cancel" styles={buttonStyleCancelDelete} />
                <PrimaryButton onClick={onConfirm} text="Delete" styles={buttonStyleConfirmDelete} />
            </Stack>
        </Modal>
    );
};

export default DeleteConfirmationModal;