import { mergeStyles } from '@fluentui/react';

export const customBoxStyleSettings = mergeStyles({
    padding: 12,
    margin: 16,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    borderRadius: '.5rem',
    marginBottom: '30px',
    display: 'block',
    maxWidth: '95%',
    justifyContent: 'center',
    alignItems:'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    }
);


export const customBoxStyleResults = mergeStyles({
    padding: 12,
    margin: 16,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    borderRadius: '0.5rem',
    marginBottom: '80px',
    maxWidth: '95%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    }   
);
