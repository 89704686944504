import React, { useState, useEffect, useRef } from 'react';
import { Label } from '@fluentui/react';
import { Icon } from '@fluentui/react/lib/Icon';
import { infoLabelDiv, infoLabelStyle, toolTipStyle, toolTipIconStyle } from './react_styles_ui';

interface CustomTooltipProps {
  label: string;
  infoText: string;
  children?: React.ReactNode;
}


const InfoLabel: React.FC<CustomTooltipProps> = ({ label, infoText, children }) => {

  // Initialize states and constants
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(infoText);
  const iconRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  // Function to handle icon click
  const handleIconClick = () => {
    const newContent = `${infoText}`; // Example link
    setTooltipContent(newContent);
    setShowTooltip(true);
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleIconClick();
    }
  };

  // Function to handle closing the icon tip when clicking outside the tool tip container
  const handleClickOutside = (event: MouseEvent) => {
    if (
      (iconRef?.current?.contains(event.target as Node)) ||
      (tooltipRef?.current?.contains(event.target as Node))
    ) {
      return;
    }
    setShowTooltip(false);
  };

  // Update on mouse click
  useEffect(() => {
    if (showTooltip) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showTooltip]);

  // Construct InfoLabel component
  return (
    <div className={infoLabelDiv}>
      <Label styles={infoLabelStyle}>{label}</Label>
      <span
        ref={iconRef}
        className={toolTipIconStyle}
        onClick={handleIconClick}
        onKeyDown={handleKeyDown}
        onMouseOver={() => { }}
        onFocus={() => { }}
        tabIndex={0}
        role="button"
        data-testid="info-icon"
        aria-label="info-button"
      >
        <Icon iconName="Info" />
      </span>
      {showTooltip && (
        <div
          ref={tooltipRef}
          className={toolTipStyle}
          dangerouslySetInnerHTML={{ __html: tooltipContent }}
        >
        </div>
      )}
      {children}
    </div>
  );
};

export default InfoLabel;