import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import dfine from '../../assets/D-fine_logo.svg';
import styles from './Layout.module.css';
import Logout from '../../components/ui/logout_button';
import { useAuth } from '../../components/auth/auth_context';
import { PrimaryButton } from '@fluentui/react';
import { buttonStyleExpander } from '../../components/react_styles';

const Layout = () => {
  // Initialize constant
  const { logout } = useAuth();
  const handleDocumentationNavigation = () => {
    window.location.href = '#/documentation';
  };

  // Construct Layout component
  return (
    <div className={styles.layout}>
      <header className={styles.header} role={'banner'}>
        <div className={styles.headerContainer}>
          <nav>
            <ul className={styles.headerNavList}>
              <li className={styles.headerNav}>
                <a
                  href="https://d-fine.de"
                  target={'_blank'}
                  rel="noreferrer"
                  title="d-fine link"
                >
                  <img
                    src={dfine}
                    alt="d-fine logo"
                    aria-label="Link to d-fine"
                    width="100px"
                    height="100px"
                    className={styles.githubLogo}
                  />
                </a>
              </li>
            </ul>
          </nav>
          <Link to="/" className={styles.headerTitleContainer}>
            <h3 className={styles.headerTitle}>Skill Search App</h3>
          </Link>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Logout Button */}
            <PrimaryButton
              onClick={handleDocumentationNavigation}
              styles={{
                ...buttonStyleExpander,
                root: {
                  ...buttonStyleExpander?.root,
                  borderColor: 'white',
                },
                rootHovered: {
                  borderColor: 'white',
                  outline: 'none',
                  backgroundColor: '#003c50d4',
                },
                rootPressed: {
                  borderColor: 'white',
                  outline: 'none',
                  backgroundColor: '#003c50d4',
                },
              }}
            >
              Documentation
            </PrimaryButton>
            <Logout onLogout={logout} />
          </div>
        </div>
      </header>

      <Outlet />
    </div>
  );
};

export default Layout;
