import React, { useState, useEffect } from 'react';
import { Checkbox, Stack } from '@fluentui/react';
import { checkboxStyles, stackCheckbox } from './react_styles_ui';

interface CheckboxOption {
    key: string;
    text: string;
};

interface CustomCheckboxProps {
    options: CheckboxOption[];
    onChange: (checkedOptions: CheckboxOption[]) => void; 
    checked: { [key: string]: boolean };
};


const CustomCheckbox: React.FunctionComponent<CustomCheckboxProps> = ({ options, onChange, checked }) => {

    // Set checked state 
    const [checkedState, setCheckedState] = useState<{ [key: string]: boolean }>(checked)

    // Handle state change events
    const handleChange = (option: CheckboxOption) => {
        const isChecked = !checkedState[option.key];
        const updatedCheckedState = { ...checkedState, [option.key]: isChecked };
        setCheckedState(updatedCheckedState);
        const updatedOptions = options.filter(opt => updatedCheckedState[opt.key]);
        if (onChange) onChange(updatedOptions);
    };

    // Update checked states
    useEffect(() => {
        setCheckedState(checked);
    }, [checked]);

    // Construct Checkbox component
    return (
        <Stack tokens={stackCheckbox}>
            {options.map(option => (
                <Checkbox
                    key={option.key}
                    label={option.text}
                    checked={checkedState[option.key] || false}
                    onChange={() => handleChange(option)}
                    styles={checkboxStyles}
                />
            ))}
        </Stack>
    );
};

export default CustomCheckbox;