import { FC, useEffect, useState } from 'react';
import { Stack, PrimaryButton, Label, mergeStyles, getTheme, Icon } from '@fluentui/react';
import styles from "./Component.module.css";
import { statisticsDivGraph, statsHeaderDiv, statisticsDiv15, statisticsDiv20, statisticsDivSmall, stackStatistics, labelStyle, labelStyleBox, buttonStyleExpander, expanderDivStyle, labelStyleGraph } from "./react_styles";
import DoughnutChartComponent from './stats/pie_chart';
import HorizontalBarChartComponent from './stats/bar_chart';
import LineChart from './stats/line_chart';
import StatsValue from './stats/stats_field';
import openaiIcon from './../../src/assets/openai-logomark.svg';
import { useAuth } from './auth/auth_context';
import {  buttonStyleSearch } from './react_styles';

const theme = getTheme();

const StatisticsComponent: FC = () => {

  // State storing
  //------------------------------------------------------------------------------------------------------------
  const [expandedStatstics, setExpandedStatstics] = useState(false);
  const { user, checkPermission } = useAuth();
  const [canViewStatisticEvaluation, setCanViewStatisticEvaluation] = useState(false);

  useEffect(() => {
    const checkUserPermission = async () => {
      if (user) {
        const hasStatisticsPermission = await checkPermission(user.role, 'CAN_VIEW_STATISTIC_EVALUATION');
        setCanViewStatisticEvaluation(hasStatisticsPermission);
      }
    };
    checkUserPermission();
  }, [user, checkPermission]);

  const handleBackNavigation = () => {
        window.location.href = '/#';
    };



  // Handling change events
  //------------------------------------------------------------------------------------------------------------
  const toggleStatsticsExpand = () => { setExpandedStatstics(!expandedStatstics) }; 

  // Construct statistics component as building block for the main app
  //------------------------------------------------------------------------------------------------------------
  return (
    <div>
      {canViewStatisticEvaluation && (
        <Stack styles={expanderDivStyle}>
          <Stack horizontal>
            <div className={mergeStyles(statsHeaderDiv)}>
              <h3 className={styles.header}>Stats overview</h3>
            </div>
            <PrimaryButton 
            onClick={toggleStatsticsExpand}
            styles={{
          ...buttonStyleExpander,
          root: {
            ...buttonStyleExpander?.root,
            marginLeft: '-20px',
            width: 110,
            height: 35,
          }
        }}
            >Show {expandedStatstics ? 'less' : 'more'} 
            </PrimaryButton>
                        <div style={{ marginLeft: 'auto' }}>
                <PrimaryButton
                    onClick={handleBackNavigation}
                    styles={{
                        ...buttonStyleSearch,
                        root: {
                            ...buttonStyleSearch?.root,
                            width: 110,
                            height: 35,
                        }
                    }}
                >Back
                </PrimaryButton>
            </div>
          </Stack>
          {expandedStatstics && (
            <div>
              <Stack horizontal styles={{ root: { height: '50%', justifyContent: 'center', marginBottom: '2%'} }}>
                <Stack styles={{ root: { width: '10%', } }}>
                  <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDivSmall)}>
                    <Icon iconName="Zoom" styles={{ root: { fontSize: '24px', marginTop: '3%', marginLeft: '3%', alignItems: 'right'} }} />
                    <Label styles={labelStyleBox}>Past 7 days searches</Label>    
                    <StatsValue apiPath="7_days_sum_searches"  />

                  </div>
                  <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDivSmall)}>
                    <img src={openaiIcon} alt="Custom Icon" style={{ width: '24px', height: '24px', marginTop: '3%', marginLeft: '3%' }} />
                    <Label styles={labelStyleBox}>Past 7 days OpenAI calls</Label>
                    <StatsValue apiPath="7_days_sum_openai_calls"  />
                  </div>
                  <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDivSmall)}>
                    <Icon iconName="Zoom" styles={{ root: { fontSize: '24px', marginTop: '3%', marginLeft: '3%' } }} />
                    <Label styles={labelStyleBox}>Total searches</Label>
                    <StatsValue apiPath="total_searches"  />
                  </div>
                  <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDivSmall)}>
                    <img src={openaiIcon} alt="Custom Icon" style={{ width: '24px', height: '24px', marginTop: '3%', marginLeft: '3%' }} />
                    <Label styles={labelStyleBox}>Total OpenAI calls</Label>
                    <StatsValue apiPath="total_openai_calls"  />
                  </div>
                </Stack>
                <Stack >
                  <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDivGraph)}>
                  <Label styles={labelStyleGraph}>Daily Searches and OpenAI calls</Label>
                  <LineChart />
                  </div>
                </Stack>
              </Stack>
              <Stack horizontal styles={stackStatistics}>
                <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDiv15)}>
                  <Label styles={labelStyle}>Most used search type</Label>
                  <DoughnutChartComponent apiPath="doughnutchart_search_type_data"  />
                </div>
                <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDiv15)}>
                  <Label styles={labelStyle}>RAG searches</Label>
                  <DoughnutChartComponent apiPath="doughnutchart_rag_search_data"  />
                </div>
                <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDiv15)}>
                  <Label styles={labelStyle}>Most used query type</Label>
                  <DoughnutChartComponent apiPath="doughnutchart_query_type_data"  />
                </div>
                <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDiv15)}>
                  <Label styles={labelStyle}>Most used search mode</Label>
                  <DoughnutChartComponent apiPath="doughnutchart_search_mode_data"  />
                </div>
              </Stack>
              <Stack horizontal styles={stackStatistics}>
                <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDiv20)}>
                <Label styles={labelStyle}>Most searched career level</Label>
                  <HorizontalBarChartComponent apiPath="barchart_career_level_data"/>
                </div>
                <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDiv20)}>
                <Label styles={labelStyle}>Most searched staffing status</Label>
                  <HorizontalBarChartComponent apiPath="barchart_staffing_status_data"/>
                </div>
                <div style={{ boxShadow: theme.effects.elevation16 }} className={mergeStyles(statisticsDiv20)}>
                <Label styles={labelStyle}>Most searched career track</Label>
                  <HorizontalBarChartComponent apiPath="barchart_career_track_data"/>
                </div>
              </Stack>
            </div>
          )}
        </Stack>)
      }
      </div>
  ); 
};

export default StatisticsComponent;