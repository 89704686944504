import React, { useState, useEffect } from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { dropdownStyles } from './react_styles_ui';

interface MultiSelectDropdownProps {
  placeholder?: string;
  options: IDropdownOption[];
  optionsStored?: IDropdownOption[];
  selectedOptions?: IDropdownOption[];
  onChange: (selectedOptionsList: IDropdownOption[]) => void;
  adjustOrder: boolean;
  dataTestId?: string;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  placeholder,
  options,
  optionsStored,
  selectedOptions = [],
  onChange,
  adjustOrder,
  dataTestId,
}) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>(() => 
    selectedOptions.map(option => option.key.toString())
  );

  useEffect(() => {
    setSelectedKeys(selectedOptions.map(option => option.key.toString()));
  }, [selectedOptions]);

  const handleChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
    if (item) {
      // Handle selection of the "All" option
      if (item.key === "ALL") {
        const currentlySelected = selectedKeys.includes("ALL");
  
        if (currentlySelected) {
          // If "All" is already selected, deselect it and clear all selections.
          setSelectedKeys([]);
          onChange([]); // Deselect all
        } else {
          // If "All" is not selected, select all options and "All".
          const allKeys = options.map(option => option.key.toString());
          setSelectedKeys([...allKeys, "ALL"]); // Select all options and include "ALL"
          onChange(options); // Notify the parent component with all options
        }
      } else {
        // For normal options
        const updatedKeys = selectedKeys.includes(item.key.toString())
          ? selectedKeys.filter(key => key !== item.key.toString()) // Remove it if already selected
          : [...selectedKeys, item.key.toString()]; // Add it if not selected
  
        // Check if all options (excluding "ALL") are selected
        const allSelected = updatedKeys.length === options.length;
        
        // Update selectedKeys accordingly
        if (allSelected) {
          setSelectedKeys([...updatedKeys, "ALL"]); // Ensure "All" is selected as well
          onChange(options); // Notify of all selected options
        } else {
          setSelectedKeys(updatedKeys); // Just update normally
          const orderedSelectedOptions = options.filter(option => updatedKeys.includes(option.key.toString()));
          onChange(orderedSelectedOptions);
        }
      }
    }
  };
  
  return (
    <Dropdown
      placeholder={placeholder}
      selectedKeys={selectedKeys}
      onChange={handleChange}
      multiSelect
      options={
        adjustOrder 
          ? options 
          : [{ key: "ALL", text: "All" }, ...options]
      }
      styles={dropdownStyles}
      data-testid={dataTestId || "dropdown-toggle"}
    />
  );
};

export default MultiSelectDropdown;
