import { API_BASE_URL } from '../api/api_base_url';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2'; // Import HorizontalBar from react-chartjs-2
import axios from 'axios';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface BarChartData {
    labels: string[];
    data: number[];
    colors: string[];
}

interface BarChartComponentProps {
    apiPath: string; // Input string parameter
}

const HorizontalBarChartComponent: React.FC<BarChartComponentProps> = ({ apiPath }) => {
    const [data, setData] = useState<BarChartData | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/stats/${apiPath}`);
                const result = response.data;
                setData(result);
            } catch (error) {
                console.error('Error fetching bar chart data:', error);
            }
        };

        fetchData();
    }, [apiPath]);

    if (!data) {
        return <p>Loading...</p>;
    }

    const chartData = {
        labels: data.labels,
        datasets: [
            {
                data: data.data,
                backgroundColor: data.colors, // Sample color
            },
        ],
    };

    const options = {
        indexAxis: 'y' as const, // This makes the chart horizontal
        responsive: true,
        scales: {
            x: {
              grid: {
                  display: false, // Hide the grid lines on the x-axis
              },
              ticks: {
                autoSkip: false,
                font: {
                    weight: 'bold' as const,
                    family: 'Roboto',
                    size: 16 // Makes the x-axis labels bold
                  },
              },
            },
            y: {
                grid: {
                    display: false, // Hide the grid lines on the x-axis
                },
                ticks: {
                    autoSkip: false,
                    font: {
                        weight: 'bold' as const,
                        family: 'Roboto',
                        size: 16 // Makes the x-axis labels bold
                      },
                }
              }
        },
        barPercentage: 0.97,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end' as const,
                align: 'end' as const,
                formatter: (value: number) => value.toString(), // Convert value to string
                color: 'black' as const, // Change the text color for visibility
            },
        },
        layout: {
            padding: {
                right: 40 // Adjust the padding as needed
            }
        },
    };

    return <Bar data={chartData} options={options} plugins={[ChartDataLabels]} width={800} height={600} />;
};

export default HorizontalBarChartComponent;
