import { renderToStaticMarkup } from "react-dom/server";
import { IDropdownOption } from '@fluentui/react';
import DOMPurify from "dompurify";


//****************************************************************************************************//

export function parseContentToHtml(content: string, role: string)  {
    // Function to parse the content of each message to HTML
    const getLinkColorByRole = (role: string) => {
        if (role === 'user') {
            return '#003c50';
        } else {
            return 'white';
        }     
    };
    // Function to replace four-digit numbers with links
    const replaceDigitNumbers = (text: string) => {
        const regex = /\b\d{3,4}\b/g;
        const linkColor = getLinkColorByRole(role);
        return text.replace(regex, (match) => {
            return `<a href="https://app.it-docker.d-fine.local/StaffingPortal/StaffApp/details/CV_View/${match}" target="_blank" style="color: ${linkColor};">${match}</a>`;
        });
    };
    const { answerHtml } = parseAnswerToHtml(content, false, () => {});
    const sanitizedHtml = DOMPurify.sanitize(answerHtml);
    const htmlWithLinks = replaceDigitNumbers(sanitizedHtml);
    const htmlWithLineBreaks = htmlWithLinks.replace(/\n/g, '<br>');
    return htmlWithLineBreaks;
};

//****************************************************************************************************//

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
};

export function parseAnswerToHtml(answer: string, isStreaming: boolean, onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {
    const citations: string[] = [];

    // Trim any whitespace from the end of the answer after removing follow-up questions
    let parsedAnswer = answer.trim();

    // Omit a citation that is still being typed during streaming
    if (isStreaming) {
        let lastIndex = parsedAnswer.length;
        for (let i = parsedAnswer.length - 1; i >= 0; i--) {
            if (parsedAnswer[i] === "]") {
                break;
            } else if (parsedAnswer[i] === "[") {
                lastIndex = i;
                break;
            }
        }
        const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
        parsedAnswer = truncatedAnswer;
    }

    const parts = parsedAnswer.split(/\[([^\]]+)\]/g);

    const fragments: string[] = parts.map((part, index) => {
        if (index % 2 === 0) {
            return part;
        } else {
            let citationIndex: number;
            if (citations.indexOf(part) !== -1) {
                citationIndex = citations.indexOf(part) + 1;
            } else {
                citations.push(part);
                citationIndex = citations.length;
            }

            const path = getCitationFilePath(part);

            return renderToStaticMarkup(
                <a className="supContainer" title={part} onClick={() => onCitationClicked(path)}>
                    <sup>{citationIndex}</sup>
                </a>
            );
        }
    });

    return {
        answerHtml: fragments.join(""),
        citations
    };
};


//****************************************************************************************************//

const BACKEND_URI = "https://app.it-docker.d-fine.local/StaffingPortal/StaffApp/details/CV_View/";

export function getCitationFilePath(citation: string): string {
    return `${BACKEND_URI}${citation}`;
}


//****************************************************************************************************//

export const calculateWidth = (content: string) => {
    // Split the content into paragraphs
    const paragraphs = content.split('\n');

    // Find the paragraph with the maximum number of words
    const maxWordCountParagraph = paragraphs.reduce((maxParagraph, currentParagraph) => {
        const wordCount = currentParagraph.split(' ').length;
        return wordCount > maxParagraph.split(' ').length ? currentParagraph : maxParagraph;
    }, '');
    // Use a fixed value to approximate the width of each character
    const approximateWidthPerCharacter = 10; // Adjust this value as needed

    // Calculate the width based on the length of the string
    const width = Math.max(maxWordCountParagraph.length * approximateWidthPerCharacter, 300);
    return width + 'px';
};

//****************************************************************************************************//

export function getMaxTextWidth(rows: any, field: any, font: any) {
    let maxWidth = 0;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    
    if (!context) return 20;
    context.font = font;

    // Iterate through each row to find the widest text width
    rows.forEach((row: { [x: string]: string; }) => {
      const text = row[field] || ''; 
      const textWidth = context.measureText(text).width;
      if (textWidth > maxWidth) {
        maxWidth = textWidth;
      }
    });
    return maxWidth + 40;
};

//****************************************************************************************************//

export function updateOptions(selectedColumns: IDropdownOption<any>[], columnsOption: IDropdownOption<any>[]) {
    const reorderedOptions = selectedColumns
      .map((selectedItem) => columnsOption.find((item) => item.key === selectedItem.key))
      .filter((item) => item !== undefined) as IDropdownOption[]; // Filter out undefined items
    const remainingOptions = columnsOption.filter((item) => !selectedColumns.find((selectedItem) => selectedItem.key === item.key));
    const updatedOptions = [...reorderedOptions, ...remainingOptions];
    return updatedOptions;
};

//****************************************************************************************************//

export function percentageCellRenderer(params: any) {
    let value = params.value;
    if (typeof value === 'number') {
        return value + ' %';
    }
    return value;
};

//****************************************************************************************************//

export function daysCellRenderer(params: any) {
    let value = params.data[params.colDef.field];
    if (value && typeof value === 'object' && 'days' in value && 'totalDays' in value) {
        return `${value.days} / ${value.totalDays}`;
    }
    return '';
};

//****************************************************************************************************//

export function daysValueGetter(params: any) {
    let value = params.data[params.colDef.field];
    if (value && typeof value === 'object' && 'days' in value) {
      return value.days;
    }
    return null; 
};

//****************************************************************************************************//

export function kmCellRenderer(params: any) {
    let value = params.value;
    if (typeof value === 'number') {
        return value + ' km';
    }
    return value;
}; 

//****************************************************************************************************//